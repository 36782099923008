import React from 'react'
import * as S from './styles'
import Texts from '@site/assets/constants/pageNotFound/texts'

const PageNotFound = (): React.ReactElement => {
  return (
    <S.Container
      className="pagenotfound__container"
    >
      <S.Content>
        <h2>
          {Texts.TITLE}
        </h2>
      </S.Content>
    </S.Container>
  )
}

export default PageNotFound
