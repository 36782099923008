import { useEffect } from 'react'

export default function useChatbot() {
  useEffect( () => {
    const chatBotScript = document.getElementById( 'chatbotScript' )

    const handler = () => {
      if ( !chatBotScript ) {
        const script = document.createElement( 'script' )
        script.id = 'chatbotScript'
        script.async = true
        script.src = process.env.GATSBY_CHATBOT_URL
        document.body.appendChild( script )
      }
      window.removeEventListener( 'scroll', handler )
    }

    window.addEventListener( 'scroll', handler )

    return () => window.removeEventListener( 'scroll', handler )
  }, [] )
}
