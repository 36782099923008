import styled from 'styled-components'
import faqBackground from '@site/images/faq/fundo-opaco.png'

const Container = styled.section`
  position: relative;
  margin-top: 48px;
  height: 400px;  
  padding: 24px 16px;
  max-width: 100%;
  background-image: url(${faqBackground});
  background-repeat: repeat;
`

const Content = styled.div`
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);

  h2 {
    text-align: center;
    margin-bottom: 32px;
    color: #EC3C6A;
    font-family: Work Sans;
    font-size: 48px;
    line-height: 48px;
    -webkit-letter-spacing: -0.03em;
    -moz-letter-spacing: -0.03em;
    -ms-letter-spacing: -0.03em;
    letter-spacing: -0.03em;
    font-weight: 700;
  }
`

export { Container, Content }
