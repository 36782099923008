import React from 'react'
import CommonHead from '@shared/meta/CommonHead'
import Menu from '@site/components/Menu/Menu'
import Footer from '@site/components/Footer/Footer'
import CookiesFab from '@site/components/organisms/CookiesFab'
import useChatbot from '@site/helpers/useChatbot'
import PageNotFound from '@site/components/organisms/PageNotFound'

export function Head() {
  return (
    <CommonHead />
  )
}

const NotFound = () => {
  useChatbot()

  return (
    <>
      <Menu />
      <PageNotFound />
      <Footer />
      <CookiesFab />
    </>
  )
}

export default NotFound
